import type { AsyncDataOptions } from '#app/composables/asyncData';

export async function useAsyncApi<T>(
  key: string,
  handler: () => Promise<T>,
  options?: AsyncDataOptions<T>,
) {
  const asyncData = await useAsyncData(key, handler, {
    lazy: true,
    ...options,
  });

  if (asyncData.error.value && import.meta.client) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Page not found',
    });
  }
  return asyncData;
}
